<template>
  <main class="container text-center">
    <div class="row pt-4">
      <div class="col">
        A data início é
        <b>
          <span>{{ startDate }}</span> </b
        >, sendo o prazo indicado de
        <b>
          <span>{{ duration }}</span>
        </b>
        dias,
        <span v-if="includeHolidays == 'true'"> (corre em Férias)</span>
        <span> a data final será:</span>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col">
        <h3 style="color: blue">{{ timelineResult.final_date_localized }}</h3>
      </div>
    </div>

    <div class="row pt-2 justify-content-center">
      <div
        class="input-group col-lg-2 col-md-3 col-sm-4 col-xs-12"
        style="min-width: 350px; display: flex; align-items: center"
      >
        <div class="input-group-addon pr-1 pt-1">
          <span class="fas fa-at fa-lg"></span>
        </div>
        <input
          type="email"
          class="form-control mt-1"
          placeholder="Endereco de e-mail"
          required
          autofocus
          ref="email"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="input-group col-lg-2 col-md-3 col-sm-4 col-xs-12"
        style="min-width: 350px; display: flex; align-items: center"
      >
        <div class="input-group-addon pr-1 pt-1">
          <span class="far fa-comment-alt fa-lg"></span>
        </div>
        <input
          type="text"
          class="form-control mt-1"
          placeholder="Assunto"
          required
          autofocus
          ref="subject"
        />
      </div>
    </div>

    <div class="row justify-content-center">
      <button class="btn btn-info mt-2" type="submit" @click="sendEmail()">
        Enviar prazo
      </button>
    </div>

    <div class="row justify-content-center pt-5 pb-3">
      <button type="submit" class="btn btn-primary" @click="backToTimeline()">
        Calcular novo prazo
      </button>
    </div>

    <hr class="col-xs-12" />

    <div class="row">
      <div class="col">
        <h3 class="font-weight-light">Verificação</h3>
      </div>
    </div>

    <div class="row">
      <div class="col"></div>
      <div class="col">
        <table class="table">
          <tbody>
            <tr v-for="item in timelineResult.items" v-bind:key="item">
              <th v-if="item.included == false" scope="row">
                <i class="fas fa-times"></i>
              </th>
              <th v-if="item.included == true" scope="row">
                <span>{{ item.counter }}</span>
              </th>
              <td align="left" style="min-width: 120px">
                <span>{{ item.date_formatted }}</span>
              </td>
              <td
                v-if="item.included == true && !item.explanation"
                align="left"
              >
                <span>{{ item.day_of_week }}</span>
              </td>
              <td v-if="item.included == false" align="left">
                <span>{{ item.explanation }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col"></div>
    </div>

    <hr class="col-xs-12" />
  </main>
</template>

<script>
export default {
  name: "Result",
  data() {
    return {
      timelineResult: {},
      startDate: this.$route.query.data_inicial,
      duration: this.$route.query.duracao,
      includeHolidays: this.$route.query.corre_em_ferias,
    };
  },
  methods: {
    backToTimeline() {
      this.$router.push("/prazos");
    },
    sendEmail() {
      var self = this;
      const request = {
        "start_date_formatted": self.startDate,
        "duration": self.duration,
        "run_on_justice_holidays": self.includeHolidays == "true",
        "final_date": self.timelineResult.final_date,
        "final_date_formatted": self.timelineResult.final_date_formatted,
        "final_date_day_of_week": self.timelineResult.final_date_day_of_week,
        "final_date_localized": self.timelineResult.final_date_localized,
        "email": this.$refs.email.value,
        "subject": this.$refs.subject.value
      };
      const url = "https://api.4lawyers.pt/notification";
      this.axios.post(url, request, {
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          mode: "no-cors",
        }).catch(function (error) {
        console.log(error);
      });
      this.$router.push("/prazos");
    },
    getTimeLine() {
      var self = this;
      var run_on_justice_holidays = self.includeHolidays == "true";
      const url =
        "https://api.4lawyers.pt/timeline?start_date=" +
        self.formatDate(self.startDate) +
        "&duration=" +
        self.duration +
        "&run_on_justice_holidays=" +
        run_on_justice_holidays;
      this.axios
        .get(url, {
          dataType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          mode: "no-cors",
        })
        .then(function (response) {
          self.timelineResult = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    formatDate(date) {
      var splitted = date.split("-");
      var year = splitted[2];
      var month = splitted[1];
      var day = splitted[0];

      return [year, month, day].join("-");
    },
  },
  created() {
    this.getTimeLine();
  },
};
</script>

<style scoped>
</style>
