<template>
    <div class="resultView">
        <Header/>
        <Result/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Result from '@/components/result/Result.vue'

export default {
  name: 'ResultView',
  components: {
    Header,
    Result
  }
}
</script>
